import React, { useEffect, useState } from 'react'
import logo from '../../Assests/logo.svg'
import Send from '../../Assests/Send.svg'
import { useNavigate } from 'react-router-dom'
import ChatInterface from './ChatInterface'
import Options from './Options'
import Loader from '../Loader'
import axios from 'axios'
import chatBackground from '../../Assests/chatBackground.svg'



function ChatPage() {

    const navigate = useNavigate('');
    const [ChatHistory, setChatHistory] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');
    const username = localStorage.getItem('username');



    const BASE_URL = 'https://rephrase-api.blaziken.in';


    const fetchChatHistory = async () => {
        try {
            const token = localStorage.getItem('token');

            const response = await axios.get(`${BASE_URL}/api/chat`, {
                headers: {
                    Authorization: token,
                },
            });
            setChatHistory(response.data.History);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        } finally {
            setLoading(false); // Set loading status to false when the API call completes
        }
    };

    const sendChatMessage = async () => {


        setLoading(true); // Set loading status to true when the API call starts
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                `${BASE_URL}/api/chat`,
                {
                    text: message,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            // Check if the response status is 200
            if (response.status === 200) {

                fetchChatHistory();
                setMessage('');
            }
        } catch (error) {
            console.error('Error sending chat message:', error);
        } finally {
            setLoading(false); // Set loading status to false when the API call completes
            setMessage('');
        }
    };


    const handleMessage = (e) => {

        setMessage(e.target.value)

        const newMessage = e.target.value;
        if (newMessage.length > 500) {
            setMessageError('Message should be no more than 500 characters!!!');
        } else {
            setMessageError('');
        }
    }


    useEffect(() => {
        fetchChatHistory(); // calling API when the page loads
    }, []);


    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/login')
        }
    }, [])

    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('username')
        navigate('/login')
    }

    const isMessageValid = message.length > 0 && message.length <= 500;

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isMessageValid && !loading) {
                sendChatMessage();
            }
        }
    };
    return (
        <div className='flex flex-col h-screen'
            style={{
                backgroundImage: `url(${chatBackground})`,
                backgroundSize: 'cover',
            }}>

            {/* navbar */}
            <div className="sticky top-0 z-50">
                <nav className="flex flex-1 items-center justify-between flex-wrap bg-blue-600 h-[69px]">

                    <div className="flex items-center flex-shrink-0 text-blue-100 ml-6">
                        <img src={logo} className=' w-12 h-12 mt-2 mb-2'></img>
                        <span className='font-inter font-semibold text-lg ml-3 '>RephraseAI</span>
                    </div>

                    <div className='flex  items-center justify-center align-center'>
                        <p className='text-white text-lg  font-inter font-semibold leading-normal z-[10] mt-2.5 '>Hello {username}!</p>
                    </div>

                    <div>
                        <button className="flex align-center text-lg px-4 py-2 leading-normal font-inter font-semibold  text-white hover:text-gray-700 hover:underline"
                            onClick={handleLogout}
                        >Sign out</button>
                    </div>

                </nav>
            </div>

            {/* middle section */}
            <div className=' overflow-y-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-100 scrollbar-rounded-lg h-[100%]'>
                {ChatHistory.length === 0 ? <Options /> : <ChatInterface chatHistory={ChatHistory} />}
            </div>

            {/* Loader */}
            {loading && <Loader />}

            {/* Error if text>500 */}
            {messageError && <p className='text-red-500 my-0 py-0 font-inter font-semibold text-center'>{messageError}</p>}

            {/* Sending mssg panel */}
            <footer className='static flex items-center bg-blue-300 h-[82px] bottom-0 left-0 w-full'>

                <div className='flex-1 flex items-center'>
                    <input type='text'
                        className='z-10 bg-white rounded-lg w-full ml-5  placeholder:text-[#d9d9d9] px-4 py-2 block focus:outline-none text-lg font-inter font-semibold'
                        placeholder='Start typing or paste your text here then press send...'
                        value={message}
                        onChange={handleMessage}
                        onKeyPress={handleKeyPress}
                    />
                </div>

                <div className='ml-4'>
                    <button
                        className={`bg-blue-500 z-10 h-10 w-16 mr-6 rounded-md inline-flex items-center ${isMessageValid ? '' : 'opacity-50 cursor-not-allowed'} `}
                        onClick={sendChatMessage}
                        disabled={!isMessageValid || loading}
                    >
                        <img src={Send} className='h-9 transform rotate-40 w-9 ml-4 mr-3 mt-0.5 mb-1' alt="Send" />
                    </button>
                </div>

            </footer>

        </div>
    )
}

export default ChatPage
