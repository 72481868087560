import React from 'react';
import './App.css';
//import the css
import 'bootstrap/dist/css/bootstrap.min.css';
import {Route,Routes} from 'react-router-dom'
import LoginPage from './Components/LoginPage/LoginPage';
import Home  from './Components/Home/Home';
import ChatPage from './Components/ChatPage/ChatPage';
import RegisterPage from './Components/RegisterPage/RegisterPage';




function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/chat" element={<ChatPage/>} />
        <Route path="/register" element={<RegisterPage/>} />
      </Routes>
    </div>
  );
}

export default App;
