import React, { useState } from 'react'
import Chatbot from '../../Assests/Chatbot.svg'
import logo from '../../Assests/logo.svg'
import GoogleLoginButton from '../GoogleLoginButton';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from 'react-router-dom'
import Loader from '../Loader'
import { BiErrorCircle } from 'react-icons/bi';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


function RegisterPage() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [err, setErr] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const BASE_URL = 'https://rephrase-api.blaziken.in';

    const navigate = useNavigate();


    const handleEmail = (e) => {
        setEmail(e.target.value)

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!emailRegex.test(email)) {
            setEmailError('Email is not valid!')
        } else {
            // Clear the email error if it's valid
            setEmailError('');
        }

    }

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'confirmPassword') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const handlePassword = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);


        if (newPassword.length < 8) {
            setPasswordError('Password must conatin atleast 8 characters!')
        } else if (!/[A-Z]/.test(newPassword)) {
            setPasswordError('Password must conatin atleast 1 capital letter!')
        } else if (!/[a-z]/.test(newPassword)) {
            setPasswordError('Password must conatin atleast small letter!')
        } else if (!/\d/.test(newPassword)) {
            setPasswordError('Password must conatin 1 number!')
        } else {
            // Clear the password error if it's valid
            setPasswordError('');
        }


    };



    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value)
    }

    const resetAlert = () => {
        setErr('');
    };


    const handleRegistration = (e) => {
        e.preventDefault();

        setIsLoading(true);

        // Make a POST request to the registration API
        axios
            .post(`${BASE_URL}/api/register`, {
                email,
                password,
            })
            .then((response) => {

                if (response.status === 200) {
                    localStorage.setItem('token', response.data.token)
                    localStorage.setItem('username', response.data.username)
                    navigate('/chat')
                }

            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    setErr(error.response.data.message)
                } else {
                    setErr(error.response.data.message);
                }
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false); // Set loading status to false when the API call completes
            });
    };

    const isSignUpDisabled =
        !email ||
        !password ||
        !confirmPassword ||
        (passwordError !== '') ||
        (emailError !== '') ||
        password !== confirmPassword;


    return (
        <div>
            <div className="h-screen flex flex-col w-full relative">
                <div className="flex-1 bg-blue-600 h-1/2 z-0"></div>
                <div className="flex-1 bg-white h-1/2 z-0"></div>
            </div>


            {/* logincard */}
            <div className="absolute inset-0 flex items-center justify-center z-10">
                <div className="flex flex-row w-[693px] h-2/3">

                    {/* left-side */}
                    <div className="flex-basis-1/2 bg-[#F3F4F6] w-[322px]">

                        {/* repharse ai logo */}
                        <div className="flex relative mb-2">
                            <img src={logo} alt="EmoR" className="mr-2 w-12 h-12 absolute left-5 top-6 drop-shadow-lg" />
                            <p className="text-blue-500 w-32 h-7 absolute left-16 top-10 drop-shadow-lg font-inter font-semibold text-lg leading-normal ml-2">RephraseAI</p>
                        </div>


                        {/* Create your account */}
                        <div className='flex flex-col items-center justify-center h-2.5 mt-20 mb-0'>
                            <p className='font-inter text-center font-semibold text-zinc-600 mt-4'>Create your account</p>
                        </div>

                        {/* alert */}
                        <div className='flex flex-col items-center justify-center h-1.5 mb-0 mt-2'>
                            {err && (
                                <div className="mb-0">
                                    <Alert variant="danger" onClose={resetAlert} className="flex items-center justify-between p-0 w-56">
                                        <p className="text-red-500 text-xs font-bold p-2 m-0">Error: {err}</p>
                                        <button type="button" className="close-button mr-2 font-bold" aria-label="Close" onClick={resetAlert}>
                                            <span aria-hidden="true" className="close-icon text-red-500 w-4 h-3.5 flex items-center justify-center">&times;</span>
                                        </button>
                                    </Alert>
                                </div>
                            )}
                        </div>



                        {/* login form */}
                        <div className='flex flex-col items-center justify-center h-4/6 mt-8'>
                            <form action='#'>

                                {/* Email Starts */}
                                <label htmlFor='email' className='w-14 h-3 font-inter text-xxs font-bold'>
                                    Email
                                </label>

                                <div className='relative'>
                                    <input
                                        name='email'
                                        type='email'
                                        id='email'
                                        placeholder='abc123@gmail.com'
                                        className=' placeholder:text-slate-400 px-2 py-2 w-56 h-8 block focus:outline-none text-xs'
                                        required
                                        value={email}
                                        onChange={handleEmail}
                                    />
                                </div>

                                <div className='flex flex-row items-center justify-center h-1 mb-0 mt-2'>

                                    {/* Email Validation error */}
                                    {emailError && (
                                        <p className='text-red-500 font-inter font-semibold text-xxs w-56 mb-0 flex flex-row'>
                                            <BiErrorCircle className='mt-0.5 mr-1' /> {emailError}
                                        </p>
                                    )}
                                </div>
                                {/* Email Ends */}



                                {/* Password Starts*/}
                                <label htmlFor="password" className='w-14 h-3 font-inter text-xxs font-bold'>
                                    Password
                                </label>

                                <div className="relative flex items-center">
                                    <input
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        id="password"
                                        placeholder="*******"
                                        className="px-2 py-2 w-56 h-8 block focus:outline-none text-xs"
                                        required
                                        value={password}
                                        onChange={handlePassword}
                                    />
                                    {/* Eye button inside the input field */}
                                    <button
                                        type="button"
                                        onClick={() => togglePasswordVisibility('password')}
                                        className="absolute right-2 top-2 focus:outline-none"
                                    >
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>
                                {/* Password Ends */}

                                {/* Confirm Password Starts */}
                                <label htmlFor="confirmPassword" className="h-3 font-inter text-xxs font-bold">
                                    Confirm Password
                                </label>

                                <div className="relative flex items-center">
                                    <input
                                        name="confirmPassword"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        id="confirmPassword"
                                        placeholder="*******"
                                        className="placeholder:text-slate-400 px-2 py-2 w-56 h-8 block focus:outline-none text-xs"
                                        required
                                        value={confirmPassword}
                                        onChange={handleConfirmPassword}
                                    />
                                    {/* Eye button inside the input field */}
                                    <button
                                        type="button"
                                        onClick={() => togglePasswordVisibility('confirmPassword')}
                                        className="absolute right-2 top-2 focus:outline-none"
                                    >
                                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </button>
                                </div>
                                {/* Confirm Password Ends */}

                                {/*Confirm Password validation errors */}
                                <div className='flex flex-col items-center justify-center h-1 mb-0 mt-2'>
                                    {confirmPassword && password !== confirmPassword && (
                                        <p className='text-red-500 font-inter font-semibold text-xxs w-56 mb-0 flex flex-row'>
                                            <BiErrorCircle className='mt-0.5 mr-1' /> Passwords do not match!
                                        </p>
                                    )}
                                </div>


                                {/* SignUp Button */}
                                <button className={`bg-${isSignUpDisabled ? 'blue-300' : 'blue-600'} w-56 h-8 rounded-[5px] text-white font-inter font-bold text-xs mt-3 cursor-${isSignUpDisabled ? 'not-allowed' : 'pointer'}`}
                                    disabled={isSignUpDisabled}
                                    onClick={handleRegistration}
                                >Sign Up</button>
                            </form>


                            {/* Loader overlay */}
                            {isLoading && <Loader />}


                            {/* or login with starts*/}
                            <div className="inline-flex items-center justify-center py-1">
                                <div className="w-24 border-t border-gray-300 flex-grow"></div>
                                <p className="text-[#4B5563] font-inter font-semibold text-xxs mx-2 my-1">Or</p>
                                <div className="w-24 border-t border-gray-300 flex-grow"></div>
                            </div>


                            {/* Google Login */}
                            <div className="flex flex-row items-center justify-center w-24  px-0 h-10 mb-1 mt-1">
                                <GoogleLoginButton />
                            </div>


                            {/* Signup link */}
                            <div className="flex items-center justify-between mt-4 mb-2">
                                <div className="flex items-start">
                                    <div className="flex items-center ">
                                        <p className="font-inter text-xs font-bold leading-3 tracking-normal text-left text-black">
                                            Already have an account?
                                        </p>
                                    </div>
                                    <Link to="/login" className="font-inter text-xxs font-semibold leading-12 tracking-tight text-right ml-4 text-blue-500">
                                        Login
                                    </Link>
                                </div>
                            </div>
                            {/* Signup link ends*/}
                        </div>


                    </div>


                    {/* right-side */}
                    <div className="flex-basis-1/2 bg-blue-300 w-1/2 flex items-center justify-center">
                        <img src={Chatbot} alt="Bot" className="w-72 h-60" />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RegisterPage
