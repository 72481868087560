import React, { useEffect, useRef, useState } from 'react';
import userAvatar from '../../Assests/user-Avatar.svg';
import botAvatar from '../../Assests/botAvatar1.svg';
import PropTypes from 'prop-types';
import Copy from '../../Assests/Copy.svg';

function formatDate(date) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
}

function formatTime(time) {

  const datetimeUTC = new Date(time.replace(/-/g, "/") + " UTC");

  // Convert the datetime to the local timezone
  var options = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    hour: '2-digit', minute: '2-digit'
  };

  const localTimeString = datetimeUTC.toLocaleTimeString('en-US', options);
  return localTimeString
}

function ChatInterface({ chatHistory }) {
  const reversedChatHistory = [...chatHistory].reverse();
  const mssgEndRef = useRef(null);

  useEffect(() => {
    // Scroll to the end of the chat when chatHistory changes
    if (mssgEndRef.current) {
      mssgEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  let currentDate = null;

  // State to track if a message is copied
  const [copiedMessageIndex, setCopiedMessageIndex] = useState(null);

  const handleCopyText = (message, index) => {
    const textarea = document.createElement("textarea");
    textarea.value = message.text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    // Set the copied message index
    setCopiedMessageIndex(index);

    // Reset the copied message index after 3 seconds
    setTimeout(() => {
      setCopiedMessageIndex(null);
    }, 3000);
  };


  return (
    <div className='h-[100%] overflow-x-hidden overflow-y-auto'>
      {reversedChatHistory.map((message, index) => {
        const messageDate = new Date(message.time).toLocaleDateString();

        const showDatePill = currentDate !== messageDate;
        currentDate = messageDate;

        return (
          <div key={index} className="w-full">
            {showDatePill && (
              <div className='text-center text-[#4A4646] mt-4 mb-2'>
                <span className=' px-2 py-1 rounded bg-[#93C5FD] font-inter font-semibold text-sm'>
                  {formatDate(message.time)}
                </span>
              </div>
            )}

            <div
              className={`flex items-start ${message.sender === 'user' ? 'justify-end' : 'justify-start'
                }`}
            >
              {message.sender === 'bot' && (
                <img
                  src={botAvatar}
                  alt='Bot Avatar'
                  className='w-14 h-14 rounded-full ml-6 mt-6 mb-2'
                />
              )}

              <div
                className={`max-w-[40%] overflow-wrap break-word rounded-2xl font-inter font-medium text-lg  mt-6  ${message.sender === 'user'
                  ? 'bg-[#4B5563] text-white ml-2 px-4 mb-2 py-2'
                  : 'bg-[#D1D5DB] text-black ml-4 pr-12 pl-4 pb-4 pt-2 relative mb-4'
                  }`}
              >
                <p className='m-0 p-0 text-ellipsis overflow-hidden'>{message.text}</p>
                {message.sender === 'user' && (
                  <div className='text-xs text-right text-white font-normal font-roboto mt-1'>
                    {formatTime(message.time)}
                  </div>
                )}
                {message.sender === 'bot' && (
                  <div className='text-xs text-right text-black font-normal font-roboto mt-1 absolute bottom-2 right-2 pt-2 pl-4 pr-1'>
                    {formatTime(message.time)}
                  </div>
                )}

                {copiedMessageIndex === index ? (
                  <p className='w-4 h-4 absolute top-2 right-9 text-xs font-semibold text-green-600'>
                    Copied!
                  </p>
                ) : (
                  <div>
                    <button
                      onClick={() => handleCopyText(message, index)}
                      className='w-6 h-6 absolute top-2 right-2  py-0.5 px-1 bg-gray-500 rounded-md text-gray-600 hover:bg-gray-400 hover:text-gray-800 transition duration-300 ease-in-out hover:cursor-pointer'
                    >
                      <img
                        src={Copy}
                        alt='copy'
                        title='copy'
                        className='w-5 h-5'
                      />
                    </button>
                  </div>
                )}

              </div>
              {message.sender === 'user' && (
                <img
                  src={userAvatar}
                  alt='User Avatar'
                  className='w-14 h-14 rounded-full mr-6 ml-4 mt-6 mb-2'
                />
              )}
            </div>
          </div>
        );
      })}
      <div ref={mssgEndRef} />
    </div>
  );
}

// Add prop validation using propTypes
ChatInterface.propTypes = {
  chatHistory: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      sender: PropTypes.oneOf(['user', 'bot']).isRequired,
    })
  ).isRequired,
};

export default ChatInterface;
