import React from 'react'
import appimg from '../../Assests/rephrasemobile.svg'
import appstore from '../../Assests/appstore.svg'
import googleplay from '../../Assests/googleplay.svg'

function App() {
    return (
        <div>
            <div className='flex flex-row w-full'>
                {/* app img */}
                <div className='w-7/12 flex flex-col items-center justify-center gap-8'>
                    <img
                        src={appimg}
                        className='h-full flex items-center justify-center  -ml-12'
                        alt='app'
                    />

                    <div>
                        <p className='text-2xl font-inter font-semibold text-white text-center'>Correct your grammar!</p>
                    </div>
                </div>


                {/* app text */}
                <div className="w-4/12 flex flex-col items-center justify-center gap-4">
                    <div className='flex flex-col  w-80 h-44 items-center justify-center'>
                        <p className='text-6xl font-inter font-semibold text-white'>Download the app.</p>
                    </div>
                    <div className='flex flex-row  flex-start'>
                        <div> 
                            <img
                                src={appstore}
                                alt='appstore'
                                className='mr-9'
                            />
                        </div>
                        <div> 
                            <img
                                src={googleplay}
                                alt='googleplay'
                            />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default App