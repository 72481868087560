import React, { useState } from 'react'
import Chatbot from '../../Assests/Chatbot.svg'
import logo from '../../Assests/logo.svg'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert';
import GoogleLoginButton from '../GoogleLoginButton'
import Loader from '../Loader'
import { Link } from 'react-router-dom'
import { FaEye, FaEyeSlash } from 'react-icons/fa';


function LoginPage() {
  const navigate = useNavigate('');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const BASE_URL = 'https://rephrase-api.blaziken.in';

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const resetAlert = () => {
    setErr('');
  };

  const isFormValid = email.trim() !== '' && password.trim() !== ''; // Check if both fields are filled

  const login = (event) => {

    event.preventDefault()

    setIsLoading(true); // Set loading status to true when the API call starts

    axios.post(`${BASE_URL}/api/login`, {
      email: email,
      password: password
    })
      .then(response => {
        console.log(response.data)
        if (response.status === 200) {
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('username', response.data.username)
          navigate('/chat')
        }

      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          setErr(error.response.data.message)
        } else {
          setErr(error.response.data.message);
        }
        console.log(error)

      })
      .finally(() => {
        setIsLoading(false); // Set loading status to false when the API call completes
      });


  }

  return (
    <div>

      {/* Background */}
      <div className="h-screen flex flex-col w-full relative">
        <div className="flex-1 bg-blue-600 h-1/2 z-0"></div>
        <div className="flex-1 bg-white h-1/2 z-0"></div>
      </div>

      {/* logincard */}
      <div className="absolute inset-0 flex items-center justify-center z-10">
        <div className="flex flex-row w-[693px] h-2/3">

          {/* left-side */}
          <div className="flex-basis-1/2 bg-[#F3F4F6] w-[322px]">

            {/* repharse ai logo */}
            <div className="flex relative mb-2">
              <img src={logo} alt="EmoR" className="mr-2 w-12 h-12 absolute left-5 top-6 drop-shadow-lg" />
              <p className="text-blue-500 w-32 h-7 absolute left-16 top-10 drop-shadow-lg font-inter font-semibold text-lg leading-normal ml-2">RephraseAI</p>
            </div>


            {/* alert */}
            <div className='flex flex-col items-center justify-center h-2.5 mt-20 mb-0'>
              {err && (
                <div className="mt-10 mb-0">
                  <Alert variant="danger" onClose={resetAlert} className="flex items-center justify-between p-0 w-56">
                    <p className="text-red-500 text-xs font-bold p-2 m-0">Error: {err}</p>
                    <button type="button" className="close-button mr-2 font-bold" aria-label="Close" onClick={resetAlert}>
                      <span aria-hidden="true" className="close-icon text-red-500 w-4 h-3.5 flex items-center justify-center">&times;</span>
                    </button>
                  </Alert>
                </div>
              )}
            </div>

            {/* login form */}
            <div className='flex flex-col items-center justify-center h-4/6 mt-8'>
              <form action='#'>
                {/* Email Starts */}
                <label htmlFor='email' className='w-14 h-3 font-inter text-xxs font-bold'>
                  Email
                </label>

                <input
                  name='email'
                  type='email'
                  id='email'
                  placeholder='abc123@gmail.com'
                  className=' placeholder:text-slate-400 px-2 py-2 w-56 h-8 block focus:outline-none text-xs'
                  required
                  value={email}
                  onChange={handleEmail}
                />
                {/* Email Ends */}

                {/* Password Starts*/}
                <label htmlFor="password" className='w-14 h-3 font-inter text-xxs font-bold'>
                  Password
                </label>

                <div className="relative flex items-center">
                  <input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    placeholder="*******"
                    className="placeholder:text-slate-400  px-2 py-2 w-56 h-8 block focus:outline-none mb-6 text-xs"
                    required
                    value={password}
                    onChange={handlePassword}
                  />
                  {/* Eye button to toggle password visibility */}
                  <button type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-2 top-2 focus:outline-none"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                {/* Password Ends*/}

                {/* Loader overlay */}
                {isLoading && <Loader />}

                {/* SignIn Button */}
                <button className={`bg-${!isFormValid ? 'blue-300' : 'blue-600'} w-56 h-8 rounded-[5px] text-white font-inter font-bold text-xs cursor-${!isFormValid ? 'not-allowed' : 'pointer'}`}
                  disabled={!isFormValid}
                  onClick={login}
                >Sign In</button>
              </form>

              {/* or login with starts*/}
              <div className="inline-flex items-center justify-center p-[4px] py-[10px]">
                <div className="w-24 border-t border-gray-300 flex-grow"></div>
                <p className="text-[#4B5563] font-inter font-semibold text-xxs mx-2 my-2">Or</p>
                <div className="w-24 border-t border-gray-300 flex-grow"></div>
              </div>

              {/* Google Login */}
              <div className="flex flex-row items-center justify-center w-24 h-14 py-2.5 px-0">
                <GoogleLoginButton />
              </div>

              {/* Signup link */}
              <div className="flex items-center justify-between mt-6">
                <div className="flex items-start">
                  <div className="flex items-center ">
                    <p className="font-inter text-xs font-bold leading-3 tracking-normal text-left text-black">
                      Don’t have an account yet?
                    </p>
                  </div>
                  <Link to="/register" className="font-inter text-xxs font-semibold leading-12 tracking-tight text-right ml-4 text-blue-500">
                    Sign Up
                  </Link>
                </div>
              </div>
              {/* Signup link ends*/}
            </div>
          </div>

          {/* right-side */}
          <div className="flex-basis-1/2 bg-blue-300 w-1/2 flex items-center justify-center">
            <img src={Chatbot} alt="Bot" className="w-72 h-60" />
          </div>
        </div>
      </div>

    </div>
  )
}

export default LoginPage
