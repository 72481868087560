import React from "react";
import fb from "../../Assests/fbfooter.svg";
import google from "../../Assests/googlefooter.svg";

function Footer() {
  return (
    <footer className="h-60 w-full bg-black text-white">
      <div className="flex flex-col items-center justify-center h-full">
        {/* socials icons */}
        <div className="flex flex-row items-center justify-center space-x-8 mt-2 mb-2">
          <img src={fb} className="text-white w-9 h-9" />
          <img src={google} />
        </div>

        {/* about options */}
        <div className="mt-8">
          <ul className="flex flex-wrap justify-center gap-9 text-white font-inter font-semibold text-xl">
            <li>
              <a href="#" className="text-white no-underline ">
                About us
              </a>
            </li>
            <li>
              <a href="#about" className="text-white no-underline ">
                Blogs
              </a>
            </li>
            <li>
              <a href="#experience" className="text-white no-underline ">
                Terms & Conditions
              </a>
            </li>
            <li>
              <a href="#portfolio" className="text-white no-underline ">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
