import React from 'react'
import logo from '../../Assests/logo.svg'
import login from '../../Assests/entypo_login.svg'
import { Link } from 'react-router-dom'

function Nav() {
    return (
        <div>
            <nav className="flex flex-1 items-center justify-between flex-wrap h-20">

                <div className="flex items-center flex-shrink-0 text-blue-100 ml-6">
                    <img src={logo} className=' w-12 h-12 mt-2 mb-2'></img>
                    <span className='font-inter font-semibold text-lg ml-3 '>RephraseAI</span>
                </div>

                <div>
                    <Link
                        to="/login"
                        className="flex align-center text-lg px-4 py-2 leading-normal font-inter font-semibold text-white text-decoration-none hover:text-gray-700"
                    >
                        Login/Register
                        <img src={login} className="h-7 w-7 ml-3" />
                    </Link>
                </div>

            </nav>
        </div>
    )
}

export default Nav