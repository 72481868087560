import React from "react";
import watsappimg from "../../Assests/Group 138.svg";
import { Link } from "react-router-dom";

function WatsappBot() {
  return (
    <div>
      <div className="flex flex-row w-full">
        <div className="w-6/12 flex flex-col space-y-12 ml-20">
          {/* textdiv */}
          <div>
            <p className="text-8xl font-inter font-semibold text-white">
              Transform the way you chat, on your WhatsApp.
            </p>
          </div>

          {/* buttondiv */}
          <div>
            <Link to="https://wa.me/15304756685">
              <button className="bg-green-600 font-inter text-xl font-semibold text-white border-2 border-transparent w-52 h-12 py-2 px-4 rounded-md shadow-md hover:shadow-xl">
                Get Started
              </button>
            </Link>
          </div>
        </div>

        <div className="w-5/12">
          <img
            src={watsappimg}
            className="h-[640px] flex flex-start mt-8 w-full"
            alt="watsapp bot"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default WatsappBot;
