import React from 'react';
import paste from '../../Assests/paste.svg';
import check from '../../Assests/check.svg';
import language from '../../Assests/language.svg';

function Options() {
    return (
       <div className='flex flex-col items-center justify-center h-[100%]'>
            <div className='flex items-center justify-center w-96 h-10 rounded-lg bg-blue-300'>
                <p className='text-gray-700 font-inter font-semibold text-lg w-60 h-5 pt-1 text-center'>
                    What Can I do for you?
                </p>
            </div>

            <div className="flex justify-center mt-10 space-x-8">
                <div className="flex flex-col items-center w-32 h-32 bg-blue-300 rounded-lg">
                    <img src={paste} className='w-10 h-10 mt-3' alt="Paste" />
                    <p className="font-inter font-semibold text-xxs w-24 h-9 mt-3 text-center">Copy paste any text and translate it easily into English.</p>
                </div>

                <div className="flex flex-col items-center w-32 h-32 bg-blue-300 rounded-lg">
                    <img src={check} className='w-10 h-10 mt-3' alt="Check" />
                    <p className="font-inter font-semibold text-xxs w-24 h-9 mt-3 text-center">Correct your grammar.</p>
                </div>

                <div className="flex flex-col items-center w-32 h-32 bg-blue-300 rounded-lg">
                    <img src={language} className='w-10 h-10 mt-3' alt="Multiple Languages" />
                    <p className="font-inter font-semibold text-xxs w-24 h-9 mt-3 text-center">Support multiple languages.</p>
                </div>
            </div>
        </div>
    );
}

export default Options;
