import React from 'react'
import webapp from '../../Assests/website.svg'
import { Link } from 'react-router-dom';

function Website() {
    return (
        <div>
            <div className='flex flex-row w-full gap-28'>

                {/* webtext */}
                <div className="w-3/12 flex flex-col item-center justify-center space-y-12 ml-28">

                    {/* textdiv */}
                    <div ><p className='text-7xl font-inter font-semibold text-white'>Or try the web version.</p></div>

                    {/* buttondiv */}
                    <div>
                    <Link to="/login">
                        <button className='bg-green-600 font-inter text-xl font-semibold text-white border-2 border-transparent w-52 h-12 py-2 px-4 rounded-md shadow-md hover:shadow-xl'>
                            Get Started
                        </button>
                        </Link>
                    </div>


                </div>

                  {/* webimg */}
                <div className="w-7/12 ">
                    <img
                        src={webapp}
                        className='h-full flex flex-start w-full'
                        alt='website'

                    >
                    </img>
                </div>


            </div>
        </div>
    )
}

export default Website