import React, { useState } from 'react'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import Loader from './Loader'
import { useNavigate } from 'react-router-dom';

function GoogleLoginButton() {

  const BASE_URL = 'https://rephrase-api.blaziken.in';
  // eslint-disable-next-line no-undef
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const navigate = useNavigate();


  const [isLoading, setIsLoading] = useState(false);
  const handleGoogleLoginSuccess = (credentialResponse) => {

    setIsLoading(true);
    const idToken = credentialResponse.credential;

    axios
      .post(`${BASE_URL}/api/google_login`, {
        id_token: idToken,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response.status)
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('username', response.data.username);
          navigate('/chat');

        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          console.log(error.response.data.message);
        } else {
          console.log(error.response.data.message || 'An error occurred');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });


  };

  const handleGoogleLoginError = () => {
    console.log('Google Login Failed');
  };
  return (
    <div>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginError}
        />
      </GoogleOAuthProvider>

      {/* Loader overlay */}
      {isLoading && <Loader />}
    </div>
  )
}

export default GoogleLoginButton
