import React from 'react'
import Nav from './Nav'
import Watsappbot from './WatsappBot'
import App from './App'
import Website from './Website'
import Footer from './Footer'


function Home() {
    return (
        <div className="flex flex-col w-full gap-20 bg-gradient-to-r from-purple-700 from 3% to-blue-700 to-97%">
            <Nav />
            <Watsappbot />
            <App />
            <Website />
            <Footer />
        </div>

    )
}

export default Home